@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

@layer components {
}


@layer utilities {

}
